import { merge } from 'lodash';
import { PartialDeep } from 'type-fest';
import CountryAddressRules from '@client/core/corelogic/models/CountryAddressRules';

const defaultCountryRules: CountryAddressRules = {
  postalCode: { translationKey: 'postal-code', shouldFetchCityState: false },
  city: { translationKey: 'city', isDropdown: false },
  state: { isDisabled: false, isRequired: false, isVisible: true, isDropdown: false },
  hkDistrict: { isVisibleForSender: false },
};

const countryRules: Record<string, PartialDeep<CountryAddressRules>> = {
  hk: {
    state: { isVisible: false },
    hkDistrict: { isVisibleForSender: true },
  },
  us: {
    postalCode: { translationKey: 'zip-code', shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  au: {
    postalCode: { shouldFetchCityState: true },
    city: { translationKey: 'suburb', isDropdown: true },
    state: { isRequired: true, isDropdown: true },
  },
  ca: {
    postalCode: { shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  mx: {
    postalCode: { shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  nz: {
    postalCode: { shouldFetchCityState: true },
    city: { isDropdown: true },
  },
  pr: {
    postalCode: { shouldFetchCityState: true },
  },
  th: {
    state: { isRequired: true },
  },
  cn: {
    state: { isRequired: true },
  },
  id: {
    state: { isRequired: true },
  },
  my: {
    state: { isRequired: true },
  },
  vn: {
    state: { isRequired: true },
  },
};

function getCountryRules(countryCode: string | undefined): CountryAddressRules {
  if (!countryCode) return defaultCountryRules;
  return merge({}, defaultCountryRules, countryRules[countryCode.toLowerCase()]);
}

export { getCountryRules };
