import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Input } from 'easyship-components';
import { toastError, toastSuccess } from '@/components/Toastify';
import { FedexStep, FedexStepEnum, ResendPinPayload } from '../types';

interface PinCodeFormProps {
  courierId: string;
  currentStep: FedexStep;
  onResendPin: ({ payload }: { payload: ResendPinPayload }) => Promise<void>;
  handleInputChange: (option: string | null, key: string) => void;
  setError: (error: string) => void;
}

function PinCodeForm({
  courierId,
  currentStep,
  onResendPin,
  handleInputChange,
  setError,
}: PinCodeFormProps) {
  const { formatMessage } = useIntl();
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const timerRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const startTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setSecondsRemaining((prev) => {
        if (prev > 1) {
          return prev - 1;
        }
        clearInterval(timerRef.current);
        return 0;
      });
    }, 1000);
  };

  const resetTimer = () => {
    setSecondsRemaining(60);
    startTimer();
  };

  function handleResendPin(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    handleInputChange(null, 'pin_code');

    const payload: ResendPinPayload = {
      id: courierId,
      account: {
        validation_option: currentStep === FedexStepEnum.PinSMS ? 'sms' : 'email',
      },
    };

    onResendPin({ payload })
      .then(() => {
        resetTimer();
        toastSuccess(formatMessage({ id: 'courier.connect.form.fedex.pin-choice.resend-success' }));
      })
      .catch((err) => {
        if (err.data && err.data.status) {
          setError(err.data.status);
        } else {
          setError(formatMessage({ id: 'toast.default-error' }));
        }
        toastError(formatMessage({ id: 'toast.default-error' }));
      });
  }

  useEffect(() => {
    startTimer();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <>
      <p className="text-base mb-2 text-ink-500">
        <FormattedMessage id="courier.connect.form.fedex.pin-choice.enter-code" />
      </p>
      <div className="grid grid-cols-2">
        <Controller
          name="pinCode"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              value={field.value || ''}
              status={fieldState.error ? 'error' : 'default'}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange(e.target.value, 'pin_code');
              }}
            />
          )}
        />
      </div>
      <p className="text-ink-100 text-base">
        <FormattedMessage id="courier.connect.form.fedex.pin-choice.code-lifetime" />
      </p>
      <div className="mb-6 mt-6 items-center flex">
        <Button color="primary" onClick={handleResendPin} disabled={secondsRemaining > 0}>
          {formatMessage({ id: 'courier.connect.form.fedex.pin-choice.resend-code' })}
        </Button>
        {secondsRemaining > 0 && (
          <p className="text-ink-100 text-base mb-0 ml-3">
            <FormattedMessage
              id="courier.connect.form.fedex.pin-choice.available-in-seconds"
              values={{ seconds: secondsRemaining }}
            />
          </p>
        )}
      </div>
    </>
  );
}

export default PinCodeForm;
