import { PostalCodeMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import { useGateways } from '@client/src/global/context/GatewayProvider';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

interface PostalCodeMatchesQueryParams extends UseQueryOptions<PostalCodeMatches> {
  countryId: number;
  postalCode: string;
}

export function usePostalCodeMatchesQuery({
  countryId,
  postalCode,
  enabled = true,
  ...params
}: PostalCodeMatchesQueryParams) {
  const { countryGateway } = useGateways();

  return useQuery({
    queryKey: ['postalCodeMatches', countryId, postalCode],
    queryFn: () => countryGateway.getPostalCodeMatches(countryId, postalCode),
    enabled: !!countryId && !!postalCode && !!enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...params,
  });
}
