import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';

export function useDefaultAddressQuery(type: string) {
  const { addressGateway } = useGateways();

  return useQuery({
    queryKey: ['defaultAddress'],
    queryFn: () => addressGateway.getDefaultAddress(type),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
