import { CountryData, SenderCountryData } from '@client/core/corelogic/models/Country';
import { StateMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import React, { createContext, useContext, useMemo } from 'react';

interface RenderCountriesOptionsProps {
  fieldValue: number;
  senderCountries?: SenderCountryData[];
}

export interface FormFieldsContextData {
  name: string;
  strictFieldsValidation: boolean;
  countries: CountryData[];
  isCountrySelectDisabled: boolean;
  renderCountriesOptions: (props: RenderCountriesOptionsProps) => JSX.Element;
  onCountryChange: (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    setStateOptions: React.Dispatch<React.SetStateAction<StateMatches[]>>,
    setCityOptions: React.Dispatch<React.SetStateAction<string[]>>
  ) => void;
  isPostalCodeMatchesQueryEnabled: (
    postalCodeValidationRegex: string | undefined,
    country: CountryData | undefined,
    postalCode: string
  ) => boolean;
  renderHkDistrict: (isHkDistrictVisible: boolean) => false | JSX.Element;
  initialCountryId?: number;
  senderCountries?: SenderCountryData[];
}

const defaults: FormFieldsContextData = {
  name: '',
  strictFieldsValidation: true,
  countries: [],
  isCountrySelectDisabled: false,
  renderCountriesOptions: () => {
    throw new Error('renderCountriesOptions is not initialized');
  },
  onCountryChange: () => {
    throw new Error('onCountryChange is not initialized');
  },
  isPostalCodeMatchesQueryEnabled: () => {
    throw new Error('isPostalCodeMatchesQueryEnabled is not initialized');
  },
  renderHkDistrict: () => false,
};

const FormFieldsContext = createContext<FormFieldsContextData | undefined>(undefined);

interface FormFieldsProviderProps extends Partial<FormFieldsContextData> {
  children: React.ReactNode;
}

function FormFieldsProvider({
  name = defaults.name,
  strictFieldsValidation = defaults.strictFieldsValidation,
  countries = defaults.countries,
  isCountrySelectDisabled = defaults.isCountrySelectDisabled,
  renderCountriesOptions = defaults.renderCountriesOptions,
  onCountryChange = defaults.onCountryChange,
  isPostalCodeMatchesQueryEnabled = defaults.isPostalCodeMatchesQueryEnabled,
  renderHkDistrict = defaults.renderHkDistrict,
  initialCountryId = defaults.initialCountryId,
  senderCountries = defaults.senderCountries,
  children,
}: FormFieldsProviderProps) {
  const value = useMemo(
    () => ({
      name,
      strictFieldsValidation,
      countries,
      isCountrySelectDisabled,
      renderCountriesOptions,
      onCountryChange,
      isPostalCodeMatchesQueryEnabled,
      renderHkDistrict,
      initialCountryId,
      senderCountries,
    }),
    [
      name,
      strictFieldsValidation,
      countries,
      isCountrySelectDisabled,
      renderCountriesOptions,
      onCountryChange,
      isPostalCodeMatchesQueryEnabled,
      renderHkDistrict,
      initialCountryId,
      senderCountries,
    ]
  );

  return <FormFieldsContext.Provider value={value}>{children}</FormFieldsContext.Provider>;
}

function useFormFields() {
  const context = useContext(FormFieldsContext);

  if (context === undefined) {
    return defaults;
  }
  return context;
}

export { FormFieldsProvider, useFormFields };
