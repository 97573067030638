import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';

/**
 * Receiver countries are the countries that we can ship to
 */
export default function useValidReceiverCountries() {
  const { countryGateway } = useGateways();

  return useQuery({
    queryKey: ['validReceiverCountries'],
    queryFn: () => countryGateway.getValidReceiverCountries(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
