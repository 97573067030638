export const isMobileOs = () => {
  const { userAgent } = navigator;
  return /android|iPhone|iPad|iPod/i.test(userAgent);
};

export const isMacintoshSafari = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMac = /Macintosh/i.test(navigator.platform);
  return isMac && isSafari;
};
