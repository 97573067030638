import angular from 'angular';
// eslint-disable-next-line import/extensions
import { AngularConnectCourierFormFedex } from './index.tsx';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.fedex';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormFedex', AngularConnectCourierFormFedex);

export default MODULE_NAME;
