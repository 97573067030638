import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DayjsUtils from '@date-io/dayjs';

interface DatePickerProps extends KeyboardDatePickerProps {}

function DatePicker(props: DatePickerProps, ref: React.ForwardedRef<HTMLInputElement>) {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        {...props}
        inputProps={{ ref }}
        inputVariant="outlined"
        variant="inline"
        format="YYYY-MM-DD"
      />
    </MuiPickersUtilsProvider>
  );
}

export default React.forwardRef(DatePicker);
