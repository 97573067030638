import React from 'react';
import useValidReceiverCountries from '@client/core/corelogic/useValidReceiverCountries';
import { useFormContext } from 'react-hook-form';
import { StateMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import { CountryData } from '@client/core/corelogic/models/Country';
import ReceiverCountryOptions, { ReceiverCountryOptionsProps } from './ReceiverCountryOptions';
import { CountryFields } from './CountryFields';
import { FormFieldsProvider } from './FormFieldsProvider';

interface ReceiverCountryFieldsProps {
  name?: string;
  strictFieldsValidation?: boolean;
}

function ReceiverCountryFields({
  name = 'receiverAddress',
  strictFieldsValidation = true,
}: ReceiverCountryFieldsProps) {
  const prefixName = name ? `${name}.` : '';

  const { data: countries = [] } = useValidReceiverCountries();
  const { setValue, clearErrors } = useFormContext();
  const isPostalCodeMatchesQueryEnabled = (
    postalCodeValidationRegex: string | undefined,
    _: CountryData | undefined,
    postalCode: string
  ) => new RegExp(postalCodeValidationRegex ?? '').test(postalCode);

  const renderCountriesOptions = ({ fieldValue }: ReceiverCountryOptionsProps) => (
    <ReceiverCountryOptions fieldValue={fieldValue} />
  );

  const handleCountryChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    setStateOptions: React.Dispatch<React.SetStateAction<StateMatches[]>>,
    setCityOptions: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    setValue(`${prefixName}countryId`, parseInt(e.target.value as string, 10), {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(`${prefixName}id`, '');
    setValue(`${prefixName}postalCode`, '', {
      shouldDirty: true,
    });
    setValue(`${prefixName}state`, '', {
      shouldDirty: true,
    });
    setValue(`${prefixName}city`, '', {
      shouldDirty: true,
    });
    setStateOptions([]);
    setCityOptions([]);
    clearErrors([
      `${prefixName}postalCode`,
      `${prefixName}state`,
      `${prefixName}city`,
      `${prefixName}taxId`,
      `${prefixName}email`,
    ]);
  };

  return (
    <FormFieldsProvider
      name={name}
      strictFieldsValidation={strictFieldsValidation}
      countries={countries}
      renderCountriesOptions={renderCountriesOptions}
      onCountryChange={handleCountryChange}
      isPostalCodeMatchesQueryEnabled={isPostalCodeMatchesQueryEnabled}
    >
      <CountryFields />
    </FormFieldsProvider>
  );
}

export default ReceiverCountryFields;
