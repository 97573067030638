import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FedexStep } from '../types';

interface ChangeStepComponentProps {
  message: string;
  button: string;
  step: FedexStep;
  onChangeStep: (step: string) => void;
}

function ChangeStepComponent({ message, button, step, onChangeStep }: ChangeStepComponentProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      <p className="text-base mb-2 text-ink-900">
        <FormattedMessage id={message} />
      </p>
      <button
        className="text-base text-blue-500 hover:text-blue-700"
        type="button"
        onClick={() => onChangeStep(step)}
      >
        {formatMessage({ id: button })}
      </button>
    </>
  );
}

export default ChangeStepComponent;
