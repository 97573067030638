import useValidReceiverCountries from '@client/core/corelogic/useValidReceiverCountries';
import React from 'react';

export interface ReceiverCountryOptionsProps {
  fieldValue: number;
}

function ReceiverCountryOptions({ fieldValue }: ReceiverCountryOptionsProps) {
  const { data: receiverCountries = [] } = useValidReceiverCountries();

  return (
    <>
      {fieldValue === -1 && <option value="" selected aria-label="empty" />}
      {receiverCountries.map((country) => (
        <option value={country.id} key={country.id}>
          {country.name}
        </option>
      ))}
    </>
  );
}

export default ReceiverCountryOptions;
