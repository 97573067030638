import angular from 'angular';
import { ReusableSenderAddressData } from '@client/core/corelogic/models/ReusableSenderAddressData';
import { IAddress, IAddressService, ReceiverAddressSuggestField } from 'typings/address';
import {
  ReceiverAddressSearchCriteria,
  ReceiverAddressSearchField,
  ReceiverAddressSearchResult,
} from '@client/core/corelogic/models/ReceiverAddressSearchResult';
import IAddressGateway from '../../corelogic/ports/address.interface';

export default class NgAddressGateway implements IAddressGateway {
  private AddressService: IAddressService;

  constructor() {
    this.AddressService = angular
      .element(document.body)
      .injector()
      .get<IAddressService>('AddressService');
  }

  getReusableShippingAddresses(): Promise<ReusableSenderAddressData[]> {
    return new Promise((resolve, reject) => {
      this.AddressService.fetchReusableShippingAddresses()
        .then((addresses) => {
          resolve(
            addresses.map((address) => ({
              id: address.id,
              addressLine1: address.line_1,
              addressLine2: address.line_2 || '',
              companyName: address.company_name,
              phoneNumber: address.contact_phone,
              email: address.contact_email,
              countryId: address.country_id,
              countryAlpha2: address.country.alpha2,
              postalCode: address.postal_code || '',
              state: address.state || '',
              city: address.city,
              nickname: address.nickname || '',
              senderName: address.contact_name,
              isDefaultSenderAddress: address.default_values.sender,
            }))
          );
        })
        .catch((e) => reject(e));
    });
  }

  searchReceiverAddress(
    search: ReceiverAddressSearchCriteria
  ): Promise<ReceiverAddressSearchResult[]> {
    const fieldMap: Record<ReceiverAddressSearchField, ReceiverAddressSuggestField> = {
      receiverName: 'destination_name',
      companyName: 'destination_company_name',
      phoneNumber: 'phone_number',
      email: 'email_address',
    };

    return new Promise((resolve, reject) => {
      this.AddressService.suggest(search.value, fieldMap[search.field])
        .then((addresses) =>
          resolve(
            addresses.map((address) => ({
              receiverName: address.destination_name || '',
              companyName: address.destination_company_name || '',
              phoneNumber: address.phone_number || '',
              email: address.email_address || '',
              countryId: address.destination_country_id,
              postalCode: address.postal_code || '',
              state: address.state || '',
              city: address.city || '',
              addressLine1: address.address_line_1 || '',
              addressLine2: address.address_line_2 || '',
            }))
          )
        )
        .catch((reason) => reject(reason));
    });
  }
  getDefaultAddress(defaultType: string): Promise<IAddress> {
    return new Promise((resolve, reject) => {
      this.AddressService.getDefaultAddress(defaultType)
        .then((address) => resolve(address))
        .catch((reason) => reject(reason));
    });
  }
}
