import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';

export default function useCurrencies() {
  const { countryGateway } = useGateways();

  return useQuery({
    queryKey: ['currencies'],
    queryFn: () => countryGateway.getAllCurrencies(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
