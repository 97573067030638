(function () {
  const STRIPE_TRANSACTION_MINIMUM_THRESHOLD = {
    // Currencies that Easyship settles transactions in
    // @see https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
    USD: 0.5,
    AUD: 0.5,
    CAD: 0.5,
    EUR: 0.5,
    GBP: 0.3,
    HKD: 4,
    SGD: 0.5,

    // Currencies that Easyship converts to HKD for settlement
    // NOTE: Stripe's documented minimums do not apply here. The limit is HKD 4.00 times the current exchange rate.
    // These are estimates designed to stay slightly above 4 HKD, after currency conversion
    ALL: 65,
    AED: 2.1,
    BAM: 1.05,
    BGN: 1.04,
    BRL: 2.75,
    CHF: 0.55,
    CNY: 3.9,
    CZK: 15,
    DKK: 4,
    HRK: 4,
    HUF: 210,
    IDR: 8400,
    ILS: 1.95,
    INR: 45,
    ISK: 75,
    JPY: 75,
    KRW: 720,
    MXN: 11.3,
    MYR: 2.5,
    NOK: 5.5,
    NZD: 0.9,
    PHP: 30,
    PLN: 2.45,
    RON: 2.64,
    SEK: 5.6,
    TRY: 9.4,
    THB: 20,
    TWD: 17,
    UAH: 17,
    VND: 13300,
  };

  const LUXOTTICA_USER_SPECIAL_TYPE_ID = 5;
  const UBER_FREIGHT_USER_SPECIAL_TYPE_ID = 9;

  const SHOW_BOOK_MEETING_CTA_HUBSPOT_TEAM_ID_LIST = [
    '26652',
    '26653',
    '93854',
    '187840',
    '336423',
    '336424',
    '416981',
    '590000',
    '1715205',
    '2980921',
  ];

  const PERSONAS_REQUIRE_PLATFORM = ['merchant', 'crowdfunding'];

  UserSession.$inject = ['UserStatusService', 'GoogleAnalytics', 'StripeService', 'HubspotService'];

  function UserSession(UserStatusService, GoogleAnalytics, StripeService, HubspotService) {
    const DEFAULT_ITEMS_PER_PAGE = 10;
    this.hasCompanyCourierAccounts = false;

    this.create = function (data) {
      this.session_token = data.session.session_token;
      this.admin_email = data.session.admin_email;
      this.user = data.user;
      this.zendeskMessagingJwt = data.zendesk_messaging_jwt;

      if (!this.user.flow_records) this.user.flow_records = { pageViews: {}, walkthrough: {} };

      if (data.company) {
        this.company = data.company;
        this.company.isUSCompany = data.company.country_id === 234;

        UserStatusService.updateActualBalance(data.company.credit_balance);
        UserStatusService.updateAvailableBalance(data.company.available_credit_balance);

        StripeService.instantiate(this._getStripeKey());
      }

      if (this.user && this.user.id) {
        GoogleAnalytics.createEvent({ userId: this.user.id.slice(-12) });
        HubspotService.identify(this.user.email);
        if (!this.user.dashboard_settings) this.user.dashboard_settings = {};
      }
    };

    this.update = function (key, data) {
      this[key] = data;
      if (key === 'session') {
        this.session_token = data.session_token;
      }
    };

    this.updateCompanyDashboardTableSettings = function (data) {
      this.company.dashboard_settings.shipments_table = {
        ...data,
      };
    };

    this.updateCompanyPickAndPackSettings = function (data) {
      this.company.dashboard_settings.pick_and_pack = {
        ...data,
      };
    };

    this.destroy = function () {
      StripeService.reset();
      this.session_token = null;
      this.user = null;
      this.company = null;
    };

    this.updateFlowRecords = function (flowRecords) {
      this.user.flow_records = flowRecords;
    };

    this.isAUCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 14;
    };

    this.isUSCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 234;
    };

    this.isNLCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 167;
    };

    this.isHKCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 96;
    };

    this.isSGCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 199;
    };

    this.isCACompany = function () {
      if (!this.company) return;
      return this.company.country_id === 39;
    };

    this.isGBCompany = function () {
      if (!this.company) return;
      return this.company.country_id === 78;
    };

    this.isLuxUser = function () {
      if (!this.company) return;
      return this.company.company_special_type_id === LUXOTTICA_USER_SPECIAL_TYPE_ID;
    };

    this.isAmexUser = function () {
      if (!this.company) return false;
      return ['CUS574932', 'CUS589129'].includes(this.company.easyship_company_id);
    };

    this.isUberFreightUser = function () {
      if (!this.company) return;
      return this.company.company_special_type_id === UBER_FREIGHT_USER_SPECIAL_TYPE_ID;
    };

    this.hasFixedOriginForShipping = function () {
      return _.get(this.user, 'shipping_country.addresses.rates_based_on_origin_address');
    };

    this.doesntSplitSenderAndPickup = function () {
      return !_.get(this.user, 'shipping_country.addresses.different_sender_and_pickup_address');
    };

    this._getStripeKey = function () {
      return _.get(this.user, 'shipping_country.finance.stripe_account_public_key');
    };

    this.getItemsPerPageValue = function (page) {
      let itemsPerPageValue =
        this.user.dashboard_settings &&
        this.user.dashboard_settings[page] &&
        this.user.dashboard_settings[page].items_per_page;

      if (!itemsPerPageValue) itemsPerPageValue = DEFAULT_ITEMS_PER_PAGE;

      // BE might send back the value as a String instead of an integer
      return parseInt(itemsPerPageValue);
    };

    this.getDefaultViews = function () {
      return this.user.dashboard_settings && this.user.dashboard_settings.default_views;
    };

    this.isCompanyEfulfilment = function () {
      return this.company && this.company.service === 'efulfilment';
    };

    this.setItemsPerPageValue = function (page, value) {
      this.user.itemsPerPage[page] = value;
    };

    this.isUnderAccountClosingReview = function () {
      return (
        this.company &&
        this.company.account_closure &&
        this.company.account_closure.state === 'under_review'
      );
    };

    this.getMinimumChargeAmount = function () {
      if (!this.company) return 0;
      return STRIPE_TRANSACTION_MINIMUM_THRESHOLD[this.company.currency] || 0;
    };

    this.getCompanyAvailableCreditBalance = function () {
      if (!this.company) return null;
      return this.company.available_credit_balance;
    };

    this.hasDomesticReturns = function () {
      return _.get(this.user, 'shipping_country.features.domestic_returns.enabled', false);
    };

    this.hasReturnFeatureSupport = function () {
      return (
        this.getCompanyDashboardSettings().beta_feature_automated_return_shipments ||
        (!this.isCompanyEfulfilment() && this.hasDomesticReturns())
      );
    };

    this.hasManifest = function () {
      return _.get(this.user, 'shipping_country.features.manifest.enabled', false);
    };

    this.getCompanyCountryId = function () {
      return this.company && this.company.country_id;
    };

    this.getCompanyCountryName = function () {
      return this.company && this.company.country_name;
    };

    this.getCompanyDisplayCurrency = function () {
      return this.company && this.company.display_currency;
    };

    this.getCompanyCurrency = function () {
      return this.company && this.company.currency;
    };

    this.getCompanyWeightUnit = function () {
      return this.company && this.company.weight_unit;
    };

    this.getCompanyDimensionsUnit = function () {
      return this.company && this.company.dimensions_unit;
    };

    this.isCompanyUsingImperialDimensions = function () {
      return this.getCompanyDimensionsUnit() === 'in';
    };

    this.getCompanyPaymentSettings = function () {
      return _.get(this.user, 'shipping_country.features.payment');
    };

    this.getCompanyBankDetails = function () {
      return _.get(this.user, 'shipping_country.bank_info');
    };

    this.getCompanyDashboardSettings = function () {
      return _.get(this.company, 'dashboard_settings');
    };

    this.getFeatureFlagsByFlagName = function (flagName) {
      return !!this.company?.feature_flags?.[flagName];
    };

    this.hasUserRole = function (slug) {
      if (!this.hasTeamRightsAndPermissionsBetaKey()) return true;

      return (
        this.user &&
        this.user.dashboard_permissions &&
        this.user.dashboard_permissions.user_roles.some(function (role) {
          return role.slug === slug && role.is_active;
        })
      );
    };

    this.getSalesTaxesExamplesRate = function () {
      switch (this.getCompanyCountryId()) {
        case 199:
          return 0.07;
        case 39:
          return 0.05;
        default:
          return 0.1;
      }
    };

    this.getCompanySelectedPlatforms = function () {
      return _.get(this.company, 'marketing_settings.platforms', []);
    };

    this.getActionableSelectedPlatform = function () {
      const platformArray = this.getCompanySelectedPlatforms();

      const shopify = 'shopify';
      const bigcommerce = 'bigcommerce';
      const doNotSellOnline = 'do not sell online';

      const emptyResponse = '';

      if (platformArray.indexOf(shopify) > -1) {
        return shopify;
      }
      if (platformArray.indexOf(bigcommerce) > -1) {
        return bigcommerce;
      }
      if (platformArray.indexOf(doNotSellOnline) > -1) {
        return emptyResponse;
      }

      return platformArray[0] || emptyResponse;
    };

    this.getUserPersona = function () {
      return this.user && this.user.persona;
    };

    this.updateOnboarding = function (data) {
      if (data.company.platforms) {
        this.company.marketing_settings.platforms = data.company.platforms;
      }

      if (data.user.persona) {
        this.user.persona = data.user.persona;
      }

      if (data.user.flow_records) {
        this.updateFlowRecords(data.user.flow_records);
      }
    };

    this.isSkyPostal = function () {
      return this.getUserPersona() === 'skypostal';
    };

    this.isParcelCast = function () {
      return this.getUserPersona() === 'parcelcast';
    };

    this.isPostageForce = function () {
      return this.getUserPersona() === 'postageforce';
    };

    this.isPackifi = function () {
      return this.getUserPersona() === 'packifi';
    };

    this.isKickstarter = function () {
      return (
        this.getUserPersona() === 'kickstarter' ||
        this.getCompanySelectedPlatforms().includes('kickstarter')
      );
    };

    this.isIndiegogo = function () {
      return (
        this.getUserPersona() === 'indiegogo' ||
        this.getCompanySelectedPlatforms().includes('indiegogo')
      );
    };

    this.isDuda = function () {
      return (
        this.getUserPersona() === 'duda_partner' ||
        this.getCompanySelectedPlatforms().includes('duda_partner')
      );
    };

    this.isUberFreight = function () {
      return (
        this.getUserPersona() === 'uber_freight' ||
        this.getCompanySelectedPlatforms().includes('uber_freight')
      );
    };

    this.isPlatformRequired = function () {
      const persona = this.getUserPersona();
      return (
        !!persona &&
        PERSONAS_REQUIRE_PLATFORM.includes(persona) &&
        !this.getCompanySelectedPlatforms().length
      );
    };

    this.hasNotSeenWalkthrough = function (flowName) {
      const hasNotSeenFlow =
        this.user &&
        this.user.flow_records &&
        this.user.flow_records.walkthrough &&
        !this.user.flow_records.walkthrough[flowName];
      return hasNotSeenFlow;
    };

    this.getCompanyId = function () {
      return this.company && this.company.id;
    };

    this.getCompanyType = function () {
      return this.company && this.company.type;
    };

    this.getUserId = function () {
      return this.user && this.user.id;
    };

    this.getCurrentUserEmail = function () {
      return this.user && this.user.email;
    };

    this.getCurrentUserFirstName = function () {
      return this.user && this.user.first_name;
    };

    this.getCurrentUserLastName = function () {
      return this.user && this.user.last_name;
    };

    this.getEasyshipCompanyId = function () {
      return this.company && this.company.easyship_company_id;
    };

    this.getCompanySignupFlow = function () {
      return this.company && this.company.signup_flow;
    };

    this.isEmailConfirmed = function () {
      return this.user && this.user.is_email_confirmed;
    };

    this.isAccountOwner = function () {
      return this.user.role.id === 10;
    };

    this.hasTeamRightsAndPermissionsBetaKey = function () {
      return !!this.getCompanyDashboardSettings().beta_feature_team_rights_and_permissions;
    };

    this._getUserRoleActions = function () {
      return this.user.role.actions;
    };

    this.getUserRights = function () {
      return (
        this.user && this.user.dashboard_permissions && this.user.dashboard_permissions.user_rights
      );
    };

    this._activeUserRoleActionsArray = function () {
      return this._removeFalseAndTransformToArray(this._getUserRoleActions());
    };

    this._activeDashboardPermissionArray = function () {
      return this._removeFalseAndTransformToArray(this.getUserRights());
    };

    this.isUserRoleSettingPricesActive = function () {
      return this._activeUserRoleActionsArray().includes('settings.showPrices');
    };

    this.isAllowedByUserRight = function (userRight) {
      return this._activeUserRoleActionsArray().includes(userRight);
    };

    this._removeFalseAndTransformToArray = function (object) {
      const filteredObject = Object.keys(object).filter(function (key) {
        return object[key] !== false;
      });
      return filteredObject;
    };

    this._createMenuItemString = function () {
      let menuPathString = 'menu';
      const functionArguments = arguments[0];
      for (let i = 0; i < functionArguments.length; i++) {
        if (functionArguments[i]) {
          menuPathString += `.${functionArguments[i]}`;
        }
      }
      return menuPathString;
    };

    this.checkMenuItem = function () {
      const userRightsArray = this.getCompanyDashboardSettings()
        .beta_feature_team_rights_and_permissions
        ? this._activeDashboardPermissionArray()
        : this._activeUserRoleActionsArray();
      const menuElementString = this._createMenuItemString(arguments);
      return userRightsArray.includes(menuElementString);
    };

    this.checkMenuList = function (item) {
      const userRightsArray = this.getCompanyDashboardSettings()
        .beta_feature_team_rights_and_permissions
        ? this._activeDashboardPermissionArray()
        : this._activeUserRoleActionsArray();

      const relatedSubItems = [];
      for (let i = 0; i < userRightsArray.length; i++) {
        if (userRightsArray[i].startsWith(`menu.${item}`)) {
          relatedSubItems.push(userRightsArray[i]);
        }
      }
      return relatedSubItems.length > 0;
    };

    this.hasPickupAddress = function () {
      return this.company.has_pickup_address;
    };

    this.hideInsuranceAddOn = function () {
      return (
        this.company &&
        this.company.dashboard_settings &&
        this.company.dashboard_settings.hide_insurance_add_on
      );
    };

    this.hideResidentialAddOn = function () {
      return (
        this.company &&
        this.company.dashboard_settings &&
        this.company.dashboard_settings.hide_residential_add_on
      );
    };

    this.hideHandoverOptionsAddOn = function () {
      return (
        this.company &&
        this.company.dashboard_settings &&
        this.company.dashboard_settings.hide_handover_options_add_on
      );
    };

    this.isHighVolumeCompany = function () {
      let volumesToBeConsideredBig;

      switch (this.company.country_id) {
        case 39: // Canada
          volumesToBeConsideredBig = ['more_than_10000', 'between_1001_and_10000'];
          break;

        case 234: // United States
          volumesToBeConsideredBig = ['more_than_8000', 'between_3000_and_8000'];
          break;

        default:
          volumesToBeConsideredBig = ['more_than_1000', 'between_201_and_1000'];
      }

      return volumesToBeConsideredBig.indexOf(this.company.expected_shipment_volume) > -1;
    };

    this.showPaypalPaymentSource = function () {
      return (
        this.getCompanyDashboardSettings().allow_paypal || this.getCompanyPaymentSettings().paypal
      );
    };

    this.showAdvancedShipmentPanelView = function () {
      return !this.user.dashboard_settings.multiple.retain_classic_view;
    };

    this.showBookMeetingCTA = function () {
      if (this.company.hubspot_properties && this.company.hubspot_properties.hubspot_team_id) {
        return SHOW_BOOK_MEETING_CTA_HUBSPOT_TEAM_ID_LIST.includes(
          this.company.hubspot_properties.hubspot_team_id
        );
      }

      return false;
    };

    this.openOnboardingMeetingLink = function () {
      const url = `https://pages.easyship.com/meetings/chris406/meetup?email=${this.getCurrentUserEmail()}&firstname=${this.getCurrentUserFirstName()}&lastname=${this.getCurrentUserLastName()}`;

      window.open(url, '_blank', 'noopener');
    };

    this.openSalesMeetingLink = function () {
      const url = `https://pages.easyship.com/meetings/meet-daniel/meeting-link?email=${this.getCurrentUserEmail()}&firstname=${this.getCurrentUserFirstName()}&lastname=${this.getCurrentUserLastName()}`;

      window.open(url, '_blank', 'noopener');
    };

    this.openCrowdfundingMeetingLink = function () {
      const url = 'https://pages.easyship.com/meetings/stephen121/crowdfunding-meeting-link';

      window.open(url, '_blank', 'noopener');
    };

    this.getUserRoles = function () {
      return (this.user.dashboard_permissions && this.user.dashboard_permissions.user_roles) || [];
    };

    this.isPickAndPackEnabled = function () {
      return this.company.feature_flags.smb_pick_and_pack;
    };

    this.isNewFedexLyocEnabled = function () {
      return !!this.company.feature_flags.beta_feature_new_fedex_lyoc;
    };

    this.isEmailVerificationNeeded = function () {
      return (
        this.user.email_verification_required &&
        this.user.mfa_new_user &&
        this.user.email_verified === false
      );
    };

    return this;
  }

  angular
    .module('app.factory.UserSession', [
      'core.services.stripe',
      'core.services.survey-sparrow',
      'app.factory.GoogleAnalytics',
      'core.config',
      'app.global.user-status',
      'app.global.hubspot',
    ])
    .factory('UserSession', UserSession);
})();
