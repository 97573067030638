import React from 'react';
import { useIntl } from 'react-intl';
import { Radio } from 'easyship-components';

interface SelectVerificationMethodFormProps {
  handleInputChange: (option: string | null, key: string) => void;
}

function SelectVerificationMethodForm({ handleInputChange }: SelectVerificationMethodFormProps) {
  const { formatMessage } = useIntl();
  return (
    <div className="inline-flex flex-col">
      <Radio
        label={formatMessage({ id: 'courier.connect.form.fedex.pin-choice.email' })}
        name="fedex-pin-choice"
        id="email"
        onChange={(event) => handleInputChange(event.target.id, 'validation_option')}
      />
      <Radio
        label={formatMessage({ id: 'courier.connect.form.fedex.pin-choice.text-message' })}
        name="fedex-pin-choice"
        id="sms"
        onChange={(event) => handleInputChange(event.target.id, 'validation_option')}
      />
    </div>
  );
}

export default SelectVerificationMethodForm;
