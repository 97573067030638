import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'easyship-components';
import { Printer } from 'easyship-components/icons';
import useGeolocationQuery from '@/hooks/queries/useGeolocationQuery';
import { isMacintoshSafari, isMobileOs } from '@app/utils/device';

export interface PdfEmbedProps {
  src: string | undefined;
  hidePrintButton: boolean;
}

export function PdfEmbedComponent({ src, hidePrintButton }: PdfEmbedProps) {
  const { isFetching: isGeolocationFetching, data: geolocation } = useGeolocationQuery();
  /**
   * Make print button accessible except in China
   * Some browsers & devices that does not have print button
   * Tested on Safari, iPad & iPhone
   */
  const [isPrintButtonAvailable, url] = useMemo(() => {
    const isAvailable =
      geolocation && geolocation.countryCode !== 'CN' && !(isMobileOs() || !isMacintoshSafari());
    const viewerUrl = isAvailable
      ? `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(src ?? '')}&embedded=true`
      : src;
    return [isAvailable, viewerUrl];
  }, [geolocation, src]);

  const handlePrintClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (src) {
      window.open(src as string, '_blank', 'noopener');
    }
  };

  return (
    <>
      {isPrintButtonAvailable && (
        <div className="m-b-10 text-right flex justify-end">
          {!hidePrintButton && (
            <Button color="primary" leftIcon={<Printer />} onClick={handlePrintClick}>
              <FormattedMessage id="global.print" />
            </Button>
          )}
        </div>
      )}
      <div className="flex items-center h-full flex-1">
        {isGeolocationFetching && <div className="spinner" />}
        {!isGeolocationFetching && (
          <iframe className="w-full h-full" title="Fedex EULA" src={url} frameBorder="0" />
        )}
      </div>
    </>
  );
}
