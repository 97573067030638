export interface CourierDetails {
  [key: string]: unknown;
}

export interface ResendPinPayload {
  id: string;
  account: {
    validation_option: 'sms' | 'email';
  };
}

export type FedexStep = 'eula' | 'form' | 'invoice' | 'pin-choice' | 'pin-sms' | 'pin-email';

type FedexStepEnumType = {
  [key: string]: FedexStep;
};

export const FedexStepEnum: FedexStepEnumType = {
  Eula: 'eula',
  Form: 'form',
  Invoice: 'invoice',
  PinChoice: 'pin-choice',
  PinSMS: 'pin-sms',
  PinEmail: 'pin-email',
};

export interface InvoiceFormValues {
  number: string;
  date: string;
  amount: string;
  currency: string;
}

interface FedexFormValuesBase {
  accountNickname: string;
}
interface FedexDefaultFormValues {
  accountNumber: string;
  companyName: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  billingAddress: { countryId: number; postalCode: string; city: string; state: string };
  line1: string;
  line2: string;
}
interface FedexCrossBorderFormValues {
  apiKey: string;
  username: string;
  password: string;
}

export type FedexFormValues = FedexFormValuesBase &
  Partial<FedexDefaultFormValues & FedexCrossBorderFormValues>;
