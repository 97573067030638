import React from 'react';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { FormProvider, useForm } from 'react-hook-form';
import { react2angular } from 'react2angular';
import { GeolocationGatewayProvider } from '@client/src/global/context/GeolocationProvider';
import {
  ConnectCourierFormFedex,
  ConnectCourierFormFedexProps,
} from '@/pages/couriers/components/ConnectCourierComponent/fedex';

export function WrappedConnectCourierFormFedex(props: ConnectCourierFormFedexProps) {
  const methods = useForm({ mode: 'all' });

  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <GeolocationGatewayProvider>
          <FormProvider {...methods}>
            <ConnectCourierFormFedex {...props} />
          </FormProvider>
        </GeolocationGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularConnectCourierFormFedex = react2angular(WrappedConnectCourierFormFedex, [
  'courierId',
  'currentStep',
  'umbrellaName',
  'error',
  'onChangeCourierDetails',
  'onChangeStep',
  'onResendPin',
]);
