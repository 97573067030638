import angular from 'angular';

import esConnectCourierFormFedEx from '../../../../../core/components/wrappers/components/AngularConnectCourierFormFedex';
import esConnectCourierFormCanadaPost from './canada-post';
import esConnectCourierFormApg from './apg';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.forms';

angular.module(MODULE_NAME, [
  esConnectCourierFormFedEx,
  esConnectCourierFormCanadaPost,
  esConnectCourierFormApg,
]);

export default MODULE_NAME;
