import React from 'react';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { react2angular } from 'react2angular';
import { GeolocationGatewayProvider } from '@client/src/global/context/GeolocationProvider';
import { PdfEmbedComponent, PdfEmbedProps } from '@/components/PdfEmbedComponent';

export function WrappedPdfEmbedComponent(props: PdfEmbedProps) {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <GeolocationGatewayProvider>
          <PdfEmbedComponent {...props} />
        </GeolocationGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularPdfEmbedComponent = react2angular(WrappedPdfEmbedComponent, [
  'src',
  'hidePrintButton',
]);
